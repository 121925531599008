import {
    OPEN_POST_OPS_DIALOG
    , CLOSE_POST_OPS_DIALOG
    , RESET
    , LaborIntelligenceState
    , LaborIntelligenceAction
} from './types';

const initialState: LaborIntelligenceState = {
    isPostOpsDialogOpen: false
};

export const openPostOpsDialog = () => {
    return {
        type: OPEN_POST_OPS_DIALOG
    };
};

export const closePostOpsDialog = () => {
    return {
        type: CLOSE_POST_OPS_DIALOG
    };
};

export const resetLaborIntelligence = (): LaborIntelligenceAction => {
    return {
        type: RESET
    };
};

export default function reducer ( state: LaborIntelligenceState = initialState, action: LaborIntelligenceAction ): LaborIntelligenceState {
    switch ( action.type ) {
        case OPEN_POST_OPS_DIALOG:
            return Object.assign( {}, state, { isPostOpsDialogOpen: true } );
        case CLOSE_POST_OPS_DIALOG:
            return Object.assign( {}, state, { isPostOpsDialogOpen: false } );
        case RESET:
            return Object.assign( {}, initialState );
        default:
            return state;
    }
}
