import {
    ConfigActionType
    , ConfigState
    , RESET
    , SET_ALL_CONFIG
    , SET_CONFIG_VALUE
} from './types';

const initialState: ConfigState = {};

export const setAllConfig = ( config: ConfigState ) => {
    return {
        type: SET_ALL_CONFIG
        , data: config
    };
};

export const setConfigValue = ( varName: string, varValue: any ) => {
    return {
        type: SET_CONFIG_VALUE
        , data: {
            varName
            , varValue
        }
    };
};

export const resetConfig = (): ConfigActionType => {
    return {
        type: RESET
    };
};

export default function reducer ( state: ConfigState = initialState, action: ConfigActionType ): ConfigState {
    switch ( action.type ) {
        case SET_ALL_CONFIG:
            return Object.assign( {}, action.data );
        case SET_CONFIG_VALUE:
            return Object.assign( {}, state, { [ action.data.varName ]: action.data.varValue } );
        case RESET:
            return Object.assign( {}, initialState );
        default:
            return state;
    }
}
