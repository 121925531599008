import {
    SET_COSTS_PER_UNIT
    , SET_HIRING_COSTS
    , SET_LEAD_TIME
    , SET_NON_PEAK_OVERTIME
    , SET_OVERHEAD_COSTS
    , SET_PEAK_OVERTIME
    , SET_PEAK_SEASON_EFFICIENCY_GAINS
    , SET_TOTAL_VALUE_CREATED
    , ValueCalculatorActionType
    , ValueCalculatorState
} from './types';

const initialState: ValueCalculatorState = {
    nonPeakOvertime: {
        metric: 'Non-peak OT'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , leadTime: {
        metric: 'Lead time'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , costsPerUnit: {
        metric: 'Cost Per Unit'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , hiringCosts: {
        metric: 'Hiring Costs'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , overheadCosts: {
        metric: 'Overhead Costs'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , peakOvertime: {
        metric: 'Peak OT'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , peakSeasonEfficiencyGains: {
        metric: 'Peak Season Efficiency Gains'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , totalValueCreated: {
        metric: 'Total Value Created'
        , costsWithVeryable: 0
        , costsWithoutVeryable: 0
        , currentValue: 0
        , potentialValue: 0
    }
    , calculationsTableData: []
};

export const setNonPeakOvertime = ( nonPeakOvertime: any ): ValueCalculatorActionType => {
    return {
        type: SET_NON_PEAK_OVERTIME
        , data: nonPeakOvertime
    };
};

export const setPeakOvertime = ( peakOvertime: any ): ValueCalculatorActionType => {
    return {
        type: SET_PEAK_OVERTIME
        , data: peakOvertime
    };
};

export const setLeadTime = ( leadTime: any ): ValueCalculatorActionType => {
    return {
        type: SET_LEAD_TIME
        , data: leadTime
    };
};

export const setCostsPerUnit = ( costsPerUnit: any ): ValueCalculatorActionType => {
    return {
        type: SET_COSTS_PER_UNIT
        , data: costsPerUnit
    };
};

export const setHiringCosts = ( hiringCosts: any ): ValueCalculatorActionType => {
    return {
        type: SET_HIRING_COSTS
        , data: hiringCosts
    };
};

export const setOverheadCosts = ( overheadCosts: any ): ValueCalculatorActionType => {
    return {
        type: SET_OVERHEAD_COSTS
        , data: overheadCosts
    };
};

export const setPeakSeasonEfficiencyGains = ( peakSeasonEfficiencyGains: any ): ValueCalculatorActionType => {
    return {
        type: SET_PEAK_SEASON_EFFICIENCY_GAINS
        , data: peakSeasonEfficiencyGains
    };
};

export const setTotalValueCreated = ( totalValueCreated: any ): ValueCalculatorActionType => {
    return {
        type: SET_TOTAL_VALUE_CREATED
        , data: totalValueCreated
    };
};

export const setCalculationsTableData = ( totalValueCreated: any ): ValueCalculatorActionType => {
    return {
        type: SET_TOTAL_VALUE_CREATED
        , data: totalValueCreated
    };
};

export default function reducer ( state: ValueCalculatorState = initialState, action: ValueCalculatorActionType ) {
    switch ( action.type ) {
        case SET_NON_PEAK_OVERTIME:
            return Object.assign( {}, state, { nonPeakOvertime: action.data }
                , {
                    calculationsTableData: [
                        action.data
                        , state.leadTime
                        , state.costsPerUnit
                        , state.hiringCosts
                        , state.overheadCosts
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_LEAD_TIME:
            return Object.assign( {}, state, { leadTime: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , action.data
                        , state.costsPerUnit
                        , state.hiringCosts
                        , state.overheadCosts
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_COSTS_PER_UNIT:
            return Object.assign( {}, state, { costsPerUnit: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.leadTime
                        , action.data
                        , state.hiringCosts
                        , state.overheadCosts
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_HIRING_COSTS:
            return Object.assign( {}, state, { hiringCosts: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.leadTime
                        , state.costsPerUnit
                        , action.data
                        , state.overheadCosts
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_OVERHEAD_COSTS:
            return Object.assign( {}, state, { overheadCosts: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.leadTime
                        , state.costsPerUnit
                        , state.hiringCosts
                        , action.data
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_PEAK_OVERTIME:
            return Object.assign( {}, state, { peakOvertime: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.nonPeakOvertime
                        , state.leadTime
                        , state.costsPerUnit
                        , state.hiringCosts
                        , state.overheadCosts
                        , action.data
                        , state.peakSeasonEfficiencyGains
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_PEAK_SEASON_EFFICIENCY_GAINS:
            return Object.assign( {}, state, { peakSeasonEfficiencyGains: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.leadTime
                        , state.costsPerUnit
                        , state.hiringCosts
                        , state.overheadCosts
                        , state.peakOvertime
                        , action.data
                        , state.totalValueCreated
                    ]
                }
            );
        case SET_TOTAL_VALUE_CREATED:
            return Object.assign( {}, state, { totalValueCreated: action.data }
                , {
                    calculationsTableData: [
                        state.nonPeakOvertime
                        , state.leadTime
                        , state.costsPerUnit
                        , state.hiringCosts
                        , state.overheadCosts
                        , state.peakOvertime
                        , state.peakSeasonEfficiencyGains
                        , action.data
                    ]
                }
            );
        default:
            return state;
    }
}
