import { ActionType } from '../types';

export const OPEN_POST_OPS_DIALOG = 'bp/labor_intelligence/OPEN_POST_OPS_DIALOG';
export const CLOSE_POST_OPS_DIALOG = 'bp/labor_intelligence/CLOSE_POST_OPS_DIALOG';
export const RESET = 'bp/labor_intelligence/RESET';

export interface LaborIntelligenceState {
    isPostOpsDialogOpen: boolean;
}

interface OpenPostOpsDialogAction extends ActionType {
    type: typeof OPEN_POST_OPS_DIALOG;
}

interface ClosePostOpsDialogAction extends ActionType {
    type: typeof CLOSE_POST_OPS_DIALOG;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}
export type LaborIntelligenceAction = OpenPostOpsDialogAction | ClosePostOpsDialogAction | ResetAction;
