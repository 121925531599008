import { ActionType } from '../types';

export const SET_PERFORMANCE_DATA = 'bp/performance/FETCH_PERFORMANCE_DATA';

export interface PerformanceState {
    data: any;
}

interface SetPerformanceDataAction extends ActionType {
    type: typeof SET_PERFORMANCE_DATA;
}

export type PerformanceDataActionType = SetPerformanceDataAction;
