// Type imports
import { AsyncActionType } from '../types';

export const FETCH_TEMPLATES = 'bp/op-templates/FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'bp/op-templates/FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_ERROR = 'bp/op-templates/FETCH_TEMPLATES_ERROR';

export interface OpTemplatesState {
    templates: any;
    error: boolean;
    pending: boolean;
}

interface FetchTemplates extends AsyncActionType {
    type: typeof FETCH_TEMPLATES;
    pending: boolean;
}

interface FetchTemplatesError extends AsyncActionType {
    type: typeof FETCH_TEMPLATES_ERROR;
    error: boolean;
    pending: boolean;
}

interface FetchTemplatesSuccess extends AsyncActionType {
    type: typeof FETCH_TEMPLATES_SUCCESS;
    templates: any[];
    pending: boolean;
}

export type OpTemplatesActionType = FetchTemplates
    | FetchTemplatesError
    | FetchTemplatesSuccess;
