import { combineReducers } from 'redux';
// Import types here
import { ConfigState } from './config/types';
import { LaborIntelligenceState } from './labor_intelligence/types';
import { MessagesState } from './messages/types';
import { OpTemplatesState } from './op_templates/types';
import { OpConfigState } from './opconfig/types';
import { OpsState } from './ops/types';
import { PaymentsState } from './payments/types';
import { PerformanceState } from './performance/types';
import { RatingsState } from './ratings/types';
import { UserState } from './user/types';
import { ValueCalculatorState } from './value_calculator/types';

// Import reducers here
import config from './config';
import laborIntelligence from './labor_intelligence';
import messages from './messages';
import opTemplates from './op_templates';
import opConfig from './opconfig';
import ops from './ops';
import payments from './payments';
import performanceData from './performance';
import ratings from './ratings';
import user from './user';
import valueCalculator from './value_calculator';

// Redux Toolkit
import actionableOpsReducer, { ActionableOpsState } from '@/modules/DashboardModule/features/HighVolumeDashboard/views/DashboardOpsAdjustments/NewAdjustmentsView/ducks/actionableOpsSlice';
import historicalOpsReducer, { HistoricalOpsState } from '@/modules/DashboardModule/features/HighVolumeDashboard/views/DashboardOpsAdjustments/NewAdjustmentsView/ducks/historicalOpsSlice';
import opDayReducer from '@/modules/DashboardModule/features/HighVolumeDashboard/views/DashboardOpsAdjustments/NewAdjustmentsView/ducks/opDaySlice';
import { adjustmentsApi } from '@/modules/DashboardModule/features/HighVolumeDashboard/views/DashboardOpsAdjustments/NewAdjustmentsView/ducks/services/api';
export interface StoreState {
    user: UserState;
    ops: OpsState;
    messages: MessagesState;
    payments: PaymentsState;
    opConfig: OpConfigState;
    config: ConfigState;
    laborIntelligence: LaborIntelligenceState;
    ratings: RatingsState;
    valueCalculator: ValueCalculatorState;
    performanceData: PerformanceState;
    opTemplates: OpTemplatesState;
    actionableOps: ActionableOpsState;
    historicalOps: HistoricalOpsState;
}

export default combineReducers( {
    // Insert reducers here
    user
    , ops
    , messages
    , payments
    , opConfig
    , config
    , laborIntelligence
    , ratings
    , valueCalculator
    , performanceData
    , opTemplates
    , actionableOps: actionableOpsReducer
    , historicalOps: historicalOpsReducer
    , opDay: opDayReducer
    , [ adjustmentsApi.reducerPath ]: adjustmentsApi.reducer
} );

// export type RootState = ReturnType<typeof combineReducers>;
