import PaymentMethod from '@/types/paymentmethod';
import {
    ADD_PAYMENT_METHOD
    , ADD_PAYMENT_METHODS
    , PaymentsActionType
    , PaymentsState
    , RESET
    , UPDATE_PAYMENT_METHOD
} from './types';

const initialState: PaymentsState = {
    paymentMethods: []
    , previousPaymentMethodsCount: undefined
};

export const addPaymentMethod = ( paymentMethod: PaymentMethod ): PaymentsActionType => {
    return {
        type: ADD_PAYMENT_METHOD
        , data: paymentMethod
    };
};

export const addPaymentMethods = ( paymentMethod: PaymentMethod ): PaymentsActionType => {
    return {
        type: ADD_PAYMENT_METHODS
        , data: paymentMethod
    };
};

export const updatePaymentMethod = ( paymentMethod: PaymentMethod ): PaymentsActionType => {
    return {
        type: UPDATE_PAYMENT_METHOD
        , data: paymentMethod
    };
};

export const resetPayments = (): PaymentsActionType => {
    return {
        type: RESET
    };
};

export default function reducer ( state: PaymentsState = initialState, action: PaymentsActionType ) {
    switch ( action.type ) {
        case ADD_PAYMENT_METHOD:
            const paymentMethod: PaymentMethod = action.data;
            if ( !state.paymentMethods.length ) paymentMethod.isDefault = true;
            return Object.assign( {}, state, {
                paymentMethods: [ ...state.paymentMethods, paymentMethod ]
                , previousPaymentMethodsCount: state.paymentMethods.length + 1
            } );
        case ADD_PAYMENT_METHODS:
            return Object.assign( {}, state, {
                paymentMethods: action.data
                , previousPaymentMethodsCount: action.data.length
            } );
        case UPDATE_PAYMENT_METHOD:
            const paymentMethods: PaymentMethod[] = [];
            const updatedPaymentMethod: PaymentMethod = action.data;
            for ( const paymentMethod of state.paymentMethods ) {
                if ( paymentMethod.id === updatedPaymentMethod.id ) {
                    if ( !updatedPaymentMethod.isRemoved ) {
                        paymentMethods.push( updatedPaymentMethod );
                    }
                } else if ( updatedPaymentMethod.isDefault ) {
                    paymentMethods.push( Object.assign( {}, paymentMethod, { isDefault: false } ) );
                } else {
                    paymentMethods.push( paymentMethod );
                }
            }
            return Object.assign( {}, state, {
                paymentMethods
                , previousPaymentMethodsCount: paymentMethods.length
            } );
        case RESET:
            return Object.assign( {}, initialState );
        default:
            return state;
    }
}
