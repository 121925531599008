import axiosInstance from './axiosInstance';

interface apiServiceInterface {
    res?: {[key: string]: any};
    next: ( e: any ) => void;
    action: {[key: string]: any};
    err?: {[key: string]: any};
    store?: any;
}

const apiService = ( store: any ) => ( next: ( e: any ) => void ) => ( action: {[key: string]: any} ) => {
    const result = next( action );
    if ( !action.meta || !action.meta.async ) {
        return result;
    }

    let { url } = action.meta;
    const {
        path
        , method = 'GET'
        , data
    } = action.meta;

    if ( !url && !path ) {
        throw new Error( `Path not specified for async action ${ action.type }` );
    }

    url = url || path;
    return axiosInstance( {
        url
        , method
        , data
    } ).then(
        res => handleResponse( {
            res
            , action
            , next
            , store
        } ),
        err => handleErrors( {
            err
            , action
            , next
        } )
    );
};

export default apiService;

function handleErrors ( {
    err
    , action
    , next
}: apiServiceInterface ) {
    next( {
        type: `${ action.type }_ERROR`
        , payload: err
        , error: err && err.message ? err.message : 'Network error'
        , meta: action.meta
    } );
    return err;
}

function handleResponse ( {
    res
    , action
    , next
}: apiServiceInterface ) {
    next( {
        type: `${ action.type }_SUCCESS`
        , payload: res
        , meta: action.meta
        , data: res?.data
    } );

    return res;
}
