import { useMemo } from 'react';
import rootReducer from '@/ducks/index';
import {
    AnyAction
    , configureStore
    , Store
} from '@reduxjs/toolkit';
import apiService from '@/utils/apiService';
import { adjustmentsApi } from '@/modules/DashboardModule/features/HighVolumeDashboard/views/DashboardOpsAdjustments/NewAdjustmentsView/ducks/services/api';

let store: Store<any, AnyAction> | undefined;

function initStore ( initialState: any ) {
    return configureStore( {
        reducer: rootReducer
        , middleware: getDefaultMiddleware => getDefaultMiddleware( { serializableCheck: false } ).concat(
            apiService
            , adjustmentsApi.middleware
        )
        , devTools: true
        , preloadedState: initialState
    } );
}

export const initializeStore = ( preloadedState: any ) => {
    let initialStore = store ?? initStore( preloadedState );

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if ( preloadedState && store ) {
        initialStore = initStore( {
            ...store.getState()
            , ...preloadedState
        } );
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if ( typeof window === 'undefined' ) return initialStore;
    // Create the store once in the client
    if ( !store ) store = initialStore;

    return initialStore;
};

export function useStore ( initialState: any ) {
    const currentStore = useMemo( () => initializeStore( initialState ), [ initialState ] );
    return currentStore;
}

export default store;
