import {
    createApi
    , fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import {
    AdjustAndCancelOpDaysRequest
    , AdjustAndCancelResponse
} from './types/AdjustAndCancel';
import {
    AdjustOrDisputeRequest
    , AdjustOrDisputeResponse
    , ReverseDisputeRequest
} from './types/AdjustAndDispute';
import {
    GetActionableAdjustmentsRequest
    , GetActionableAdjustmentsResponse
    , GetHistoricalAdjustmentsRequest
    , GetHistoricalAdjustmentsResponse
} from './types/GetAdjustments';
import {
    AbsenceOpDayRequest
    , AbsenceOpDayResponse
} from './types/absence';

export const adjustmentsApi = createApi( {
    reducerPath: 'adjustmentsApi'
    , baseQuery: fetchBaseQuery( {
        baseUrl: `${ process.env.NEXT_PUBLIC_BE_URL }/business-portal-web/`
        , credentials: 'include'
        , headers: {
            'Authorization': `JWT ${ process.env.NEXT_PUBLIC_BFF_KEY }`
        }
    } )
    , endpoints: builder => ( {
        getActionableOpDays: builder.mutation<GetActionableAdjustmentsResponse, GetActionableAdjustmentsRequest>( {
            query: arg => {
                const {
                    businessId
                    , locationId
                    , workAreaId
                    , opId
                    , skipSort
                    , failedAdjustmentIds
                } = arg;
                return {
                    url: `/businesses/${ businessId }/actionable-op-days`
                    , params: {
                        locationId
                        , workAreaId
                        , opId
                    }
                };
            }
        } )
        , adjustOpDays: builder.mutation<AdjustOrDisputeResponse, AdjustOrDisputeRequest>( {
            query ( opDaysToAdjustOrDispute ) {
                return {
                    url: '/adjustments/dispute/'
                    , method: 'PUT'
                    , body: opDaysToAdjustOrDispute
                };
            }
        } )
        , disputeOpDays: builder.mutation<AdjustOrDisputeResponse, AdjustOrDisputeRequest>( {
            query ( opDaysToAdjustOrDispute ) {
                return {
                    url: '/adjustments/dispute/'
                    , method: 'PUT'
                    , body: opDaysToAdjustOrDispute
                };
            }
        } )
        , reverseDisputeOpDays: builder.mutation<any, ReverseDisputeRequest>( {
            query ( {
                businessId
                , opId
                , assignmentId
            } ) {
                return {
                    url: `/businesses/${ businessId }/ops/${ opId }/assignments/${ assignmentId }/disputes`
                    , method: 'DELETE'
                };
            }
        } )
        , endOp: builder.mutation<AdjustAndCancelResponse, AdjustAndCancelOpDaysRequest>( {
            query ( opDaysToAdjustOrCancel ) {
                return {
                    url: '/adjustments/cancel/'
                    , method: 'PUT'
                    , body: opDaysToAdjustOrCancel
                };
            }
        } )
        , getHistoricalOpDays: builder.mutation<GetHistoricalAdjustmentsResponse, GetHistoricalAdjustmentsRequest>( {
            query: args => {
                const {
                    businessId
                    , startDate
                    , endDate
                    , locationId
                    , workAreaId
                } = args;
                return {
                    url: `/businesses/${ businessId }/historical-op-days`
                    , params: {
                        startDate
                        , endDate
                        , locationId
                        , workAreaId
                    }
                };
            }
        } )
        , markAbsentOpDay: builder.mutation<AbsenceOpDayResponse, AbsenceOpDayRequest>( {
            query ( args ) {
                const {
                    businessId
                    , opId
                    , assignmentId
                    , opDayId
                    , opDayToMarkAbsent
                } = args;
                return {
                    url: `/businesses/${ businessId }/ops/${ opId }/assignments/${ assignmentId }/op-days/${ opDayId }/absences`
                    , method: 'POST'
                    , body: opDayToMarkAbsent
                };
            }
        } )
        , restoreOpDayPay: builder.mutation<AbsenceOpDayResponse, AbsenceOpDayRequest>( {
            query ( args ) {
                const {
                    businessId
                    , opId
                    , assignmentId
                    , opDayId
                } = args;
                return {
                    url: `/businesses/${ businessId }/ops/${ opId }/assignments/${ assignmentId }/op-days/${ opDayId }/absences`
                    , method: 'DELETE'
                };
            }
        } )
    } )
} );

export const {
    useGetActionableOpDaysMutation
    , useAdjustOpDaysMutation
    , useDisputeOpDaysMutation
    , useReverseDisputeOpDaysMutation
    , useEndOpMutation
    , useGetHistoricalOpDaysMutation
    , useMarkAbsentOpDayMutation
    , useRestoreOpDayPayMutation
} = adjustmentsApi;
