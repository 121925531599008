import PaymentMethod from '@/types/paymentmethod';
import { ActionType } from '../types';

export const ADD_PAYMENT_METHOD = 'bp/payments/ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHODS = 'bp/payments/ADD_PAYMENT_METHODS';
export const UPDATE_PAYMENT_METHOD = 'bp/payments/UPDATE_PAYMENT_METHOD';
export const RESET = 'bp/payments/RESET';

export interface PaymentsState {
    paymentMethods: PaymentMethod[];
    previousPaymentMethodsCount: number | undefined;
}

interface AddPaymentMethodAction extends ActionType {
    type: typeof ADD_PAYMENT_METHOD;
}

interface AddPaymentMethodsAction extends ActionType {
    type: typeof ADD_PAYMENT_METHODS;
}

interface UpdatePaymentMethodAction extends ActionType {
    type: typeof UPDATE_PAYMENT_METHOD;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}

export type PaymentsActionType = AddPaymentMethodAction
    | AddPaymentMethodsAction
    | UpdatePaymentMethodAction
    | ResetAction;
