// Libraries
import { createSlice } from '@reduxjs/toolkit';

// API
import { adjustmentsApi } from './services/api';

// Types
import { StoreState } from '@/ducks';
import { OpDay } from '@/types/opDay';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface HistoricalOpsState {
    historicalAdjustmentsResponse: {
        hourlyOps: OpDay[];
        pieceWorkOps: OpDay[];
    };
    historicalOpDays: {
        hourlyOps: OpDay[];
        pieceWorkOps: OpDay[];
    };
    refetchOpDays: boolean;
}

const initialOpsState: HistoricalOpsState = {
    historicalAdjustmentsResponse: {
        hourlyOps: []
        , pieceWorkOps: []
    }
    , historicalOpDays: {
        hourlyOps: []
        , pieceWorkOps: []
    }
    , refetchOpDays: true
};

export const historicalOpsSlice = createSlice( {
    name: 'historicalOpDays'
    , initialState: initialOpsState
    , reducers: {
        setOps ( state, action: PayloadAction<any> ) {
            Object.assign( state, action.payload );
        }
    }
    , extraReducers: builder => (
        builder
            // All addCase calls should be added before addMatcher calls
            .addMatcher( adjustmentsApi.endpoints.getHistoricalOpDays.matchPending, () => {
                //  TODO: Add loading state
            } )
            .addMatcher( adjustmentsApi.endpoints.getHistoricalOpDays.matchFulfilled, ( state, action ) => {
                state.historicalAdjustmentsResponse = action.payload;
                const hourlyOps: OpDay[] = [];
                for ( let index = 0; index < action.payload.hourlyOps.length; index++ ) {
                    const opDay = action.payload.hourlyOps[ index ];
                    opDay.amount /= 100;
                    opDay.assignment.bid.amount /= 100;
                    hourlyOps.push( opDay );
                }
                const pieceWorkOps: OpDay[] = [];
                for ( let index = 0; index < action.payload.pieceWorkOps.length; index++ ) {
                    const opDay = action.payload.pieceWorkOps[ index ];
                    opDay.amount /= 100;
                    opDay.assignment.bid.amount /= 100;
                    pieceWorkOps.push( opDay );
                }
                state.historicalOpDays = {
                    hourlyOps
                    , pieceWorkOps
                };
                state.refetchOpDays = false;
            } )
            .addMatcher( adjustmentsApi.endpoints.getHistoricalOpDays.matchRejected, ( state, action ) => {
                console.error( 'ERROR', action.error );
                state.refetchOpDays = false;
            } )
    )
} );

// Selectors
export const selectHistoricalAdjustmentsResponse = ( state: StoreState ) => state.historicalOps.historicalAdjustmentsResponse;
export const selectHistoricalOpDays = ( state: StoreState ) => state.historicalOps.historicalOpDays;
export const selectRefetchOpDays = ( state: StoreState ) => state.historicalOps.refetchOpDays;

export const { setOps } = historicalOpsSlice.actions;

export default historicalOpsSlice.reducer;
