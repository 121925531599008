import { MessageFeedSection } from '@/types/messages';
import {
    ActionType
    , AsyncActionType
} from '../types';

export const FETCH_MESSAGES = 'bp/messages/FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'bp/messages/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'bp/messages/FETCH_MESSAGES_ERROR';
export const FETCH_UNREAD_COUNT = 'bp/messages/FETCH_UNREAD_COUNT';
export const FETCH_UNREAD_COUNT_SUCCESS = 'bp/messages/FETCH_UNREAD_COUNT_SUCCESS';
export const FETCH_UNREAD_COUNT_ERROR = 'bp/messages/FETCH_UNREAD_COUNT_ERROR';
export const MARK_MESSAGE_READ = 'bp/messages/MARK_MESSAGE_READ';
export const MARK_MESSAGE_READ_SUCCESS = 'bp/messages/MARK_MESSAGE_READ_SUCCESS';
export const MARK_MESSAGE_READ_ERROR = 'bp/messages/MARK_MESSAGE_READ_SUCCESS';
export const RESET = 'bp/messages/RESET';
export const UPDATE_UNREAD_MESSAGE_COUNT = 'bp/messages/UPATE_UNREAD_MESSAGE_COUNT';

export interface MessagesState {
    pendingMessageFetch: boolean;
    pendingMessageCountFetch: boolean;
    pendingMarkRead: boolean;
    pendingMarkReadMessageIds: number[];
    messageFetchError: boolean;
    messageCountFetchError: boolean;
    markReadError: boolean;
    messageFeedSections: MessageFeedSection[];
    unreadMessageCount: number;
}

interface FetchMessagesAction extends AsyncActionType {
    type: typeof FETCH_MESSAGES;
}

interface FetchMessagesSuccessAction extends AsyncActionType {
    type: typeof FETCH_MESSAGES_SUCCESS;
}

interface FetchMessagesErrorAction extends AsyncActionType {
    type: typeof FETCH_MESSAGES_ERROR;
}

interface FetchUnreadCountAction extends AsyncActionType {
    type: typeof FETCH_UNREAD_COUNT;
}

interface FetchUnreadCountSuccessAction extends AsyncActionType {
    type: typeof FETCH_UNREAD_COUNT_SUCCESS;
}

interface FetchUnreadCountErrorAction extends AsyncActionType {
    type: typeof FETCH_UNREAD_COUNT_ERROR;
}

interface MarkMessageReadAction extends AsyncActionType {
    type: typeof MARK_MESSAGE_READ;
}

interface MarkMessageReadSuccessAction extends AsyncActionType {
    type: typeof MARK_MESSAGE_READ_SUCCESS;
}

interface MarkMessageReadErrorAction extends AsyncActionType {
    type: typeof MARK_MESSAGE_READ_ERROR;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}

interface UpateUnreadMessageCountAction extends ActionType {
    type: typeof UPDATE_UNREAD_MESSAGE_COUNT;
}

export type MessageActionType = FetchMessagesAction
    | FetchMessagesErrorAction
    | FetchMessagesSuccessAction
    | FetchUnreadCountAction
    | FetchUnreadCountErrorAction
    | FetchUnreadCountSuccessAction
    | MarkMessageReadAction
    | MarkMessageReadErrorAction
    | MarkMessageReadSuccessAction
    | ResetAction
    | UpateUnreadMessageCountAction;
