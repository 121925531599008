// Libraries
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { addMinutes } from 'date-fns';
import _ from 'lodash';

// API
import { adjustmentsApi } from './services/api';

// Utils
import { IWorkWeekDay } from '@/utils/workWeekArr';

// Types
import { StoreState } from '@/ducks';
import { OpDay } from '@/types/opDay';
import { AdjustAndCancelOpDaysRequest } from './services/types/AdjustAndCancel';
import {
    AdjustOrDisputeRequest
    , BackFillOp
} from './services/types/AdjustAndDispute';
import { GetActionableAdjustmentsResponse } from './services/types/GetAdjustments';

export interface ActionableOpsState {
    actionableAdjustmentsResponse: GetActionableAdjustmentsResponse;
    adjustableOpDays: GetActionableAdjustmentsResponse;
    opDaysToAdjustOrDispute: AdjustOrDisputeRequest;
    operatorsToRemove: AdjustAndCancelOpDaysRequest;
    totalAdjustedOpDays: number;
    refetchOpDays: boolean;
    isLoading: boolean;
    isError: boolean;
    isPayChecked: boolean;
    backFillOp: BackFillOp;
}

const initialActionableOpsState: ActionableOpsState = {
    actionableAdjustmentsResponse: {
        hourlyOps: []
        , pieceWorkOps: []
    }
    , adjustableOpDays: {
        hourlyOps: []
        , pieceWorkOps: []
    }
    , opDaysToAdjustOrDispute: {
        opDaysToAdjust: []
        , opDaysToDispute: []
    }
    , operatorsToRemove: {
        opDaysToAdjust: []
        , opDaysToCancel: []
    }
    , totalAdjustedOpDays: 0
    , refetchOpDays: false
    , isLoading: false
    , isError: false
    , isPayChecked: false
    , backFillOp: {} as BackFillOp
};

export const actionableOpsSlice = createSlice( {
    name: 'adjustments'
    , initialState: initialActionableOpsState
    , reducers: {
        setOpDayPay ( state, action: PayloadAction<{ id: number; adjustedOpDayAmount: number; pieces?: number }> ) {
            const opDaysToAdjust = state.opDaysToAdjustOrDispute.opDaysToAdjust;
            const updateSlice = ( sliceToUpdate: OpDay[], isPieces: boolean ) => {
                for ( const opDay of sliceToUpdate ) {
                    if ( opDay.id === action.payload.id ) {
                        const originalAmount = opDay.assignment.bid.amount;
                        const adjustmentLimit = originalAmount * 2;
                        // i don't believe we use amount to calculate anything anymore. it should just be a result of time or pieces, so rounding here should be fine(?)
                        opDay.amount = Number( action.payload.adjustedOpDayAmount.toFixed( 2 ) );
                        opDay.refAmount = Number( action.payload.adjustedOpDayAmount.toFixed( 2 ) );
                        if ( isPieces ) {
                            opDay.pieces = action.payload.pieces || null;
                            opDay.refPieces = action.payload.pieces;
                        }
                        const opDayToAdjust = opDaysToAdjust.find( existing => existing.id === opDay.id );
                        if (
                            !opDayToAdjust
                            && opDay.amount !== 0
                            && opDay.amount <= adjustmentLimit
                            && (
                                ( opDay.amount !== originalAmount && !opDay.isAdjusted )
                                || ( opDay.amount !== opDay.adjustedAmount && opDay.isAdjusted )
                            )
                        ) {
                        // Add to opDaysToAdjust array if the opDay is not already in there
                            const newOpDay = {
                                id: opDay.id
                                , amount: opDay.pieces ? null : opDay.amount
                                , pieces: opDay.pieces || null
                            };
                            opDaysToAdjust.push( newOpDay );
                            state.totalAdjustedOpDays += 1;
                        } else if (
                            opDayToAdjust
                        && (
                            ( opDay.amount === originalAmount && !opDay.isAdjusted )
                            || ( opDay.amount === opDay.adjustedAmount && opDay.isAdjusted )
                            || opDay.amount === 0
                            || opDay.amount > adjustmentLimit
                        )
                        ) {
                        // Remove opDay from opDaysToAdjust array if it's not currently adjusting, or if amount is 0
                            opDaysToAdjust.splice( opDaysToAdjust.findIndex( existing => existing.id === opDay.id ), 1 );
                            state.totalAdjustedOpDays -= 1;
                        } else if ( opDayToAdjust ) {
                        // Update opDay in opDaysToAdjust array
                            if ( opDayToAdjust?.amount ) {
                                opDayToAdjust.amount = Number( opDay.amount.toFixed( 2 ) );
                            } else {
                                opDayToAdjust.pieces = opDay.pieces;
                            }
                        }
                        // Update opDay's currentlyAdjusting flag
                        if ( opDay.isAdjusted && opDay.amount !== 0 ) {
                            opDay.currentlyAdjusting = opDay.amount !== opDay.adjustedAmount;
                        } else {
                            opDay.currentlyAdjusting = opDay.amount !== originalAmount;
                        }
                    }
                }
            };
            updateSlice( state.adjustableOpDays.hourlyOps, false );
            updateSlice( state.adjustableOpDays.pieceWorkOps, true );
        }
        , editAdjustment: ( state, action: PayloadAction<{ id: number }> ) => {
            state.adjustableOpDays.hourlyOps.concat( state.adjustableOpDays.pieceWorkOps ).forEach( ( opDay: OpDay ) => {
                if ( opDay.id === action.payload.id ) {
                    opDay.isAdjustmentsViewOpen = true;
                }
            } );
        }
        , cancelAdjustment: ( state, action: PayloadAction<{ id: number }> ) => {
            state.adjustableOpDays.hourlyOps.concat( state.adjustableOpDays.pieceWorkOps ).forEach( ( opDay: OpDay ) => {
                if ( opDay.id === action.payload.id ) {
                    opDay.currentlyAdjusting = true;
                    if ( opDay.isAdjusted ) {
                        if ( opDay.refAmount ) {
                            opDay.pieces = opDay.refPieces || null;
                            opDay.amount = opDay.refAmount;
                            opDay.currentlyAdjusting = opDay.refAmount !== opDay.adjustedAmount;
                        } else {
                            opDay.amount = opDay.adjustedAmount ?? 0;
                            opDay.pieces = opDay.adjustedPieces ?? 0;
                            opDay.currentlyAdjusting = false;
                        }
                    } else if ( opDay.refAmount ) {
                        if ( ( opDay.amount === opDay.assignment.bid.amount ) || ( opDay.assignment.bid.amount === opDay.refAmount ) ) {
                            opDay.currentlyAdjusting = false;
                        } else {
                            opDay.currentlyAdjusting = true;
                        }
                        opDay.amount = opDay.refAmount ?? 0;
                    } else {
                        opDay.isAdjustmentsViewOpen = true;
                        opDay.currentlyAdjusting = false;
                        opDay.amount = opDay.assignment.bid.amount;
                        opDay.pieces = opDay.assignment.bid.pieceWork?.pieces ?? 0;
                        opDay.refAmount = opDay.amount;
                    }
                }
            } );
            state.totalAdjustedOpDays = state.opDaysToAdjustOrDispute.opDaysToAdjust.length;
        }
        , cancelEditAdjustment: ( state, action: PayloadAction<{ id: number }> ) => {
            state.adjustableOpDays.hourlyOps.concat( state.adjustableOpDays.pieceWorkOps ).forEach( ( opDay: OpDay ) => {
                if ( opDay.id === action.payload.id ) {
                    opDay.isAdjustmentsViewOpen = false;
                    opDay.currentlyAdjusting = false;
                    opDay.amount = opDay.adjustedAmount ?? opDay.assignment.bid.amount;
                    opDay.pieces = opDay.adjustedPieces ?? opDay.assignment.bid.pieceWork?.pieces ?? null;
                }
            } );
            state.operatorsToRemove.opDaysToAdjust = [];
            state.opDaysToAdjustOrDispute.opDaysToAdjust = [];
            state.totalAdjustedOpDays = state.opDaysToAdjustOrDispute.opDaysToAdjust.length;
        }
        , addToAdjustedOperatorToRemove ( state, action: PayloadAction<{ id: number; adjustedOpDayAmount: number; pieces?: number }> ) {
            const opDayExists = !!state.operatorsToRemove.opDaysToAdjust.find( existing => existing.id === action.payload.id );
            for ( const opDay of state.adjustableOpDays.pieceWorkOps ) {
                if ( opDay.id === action.payload.id ) {
                    const originalAmount = opDay.assignment.bid.amount;
                    const adjustmentLimit = originalAmount * 2;
                    opDay.amount = action.payload.adjustedOpDayAmount;
                    opDay.pieces = action.payload.pieces || null;
                    opDay.currentlyAdjusting = true;
                    const isAdjustableOpDay = ( !opDayExists && !opDay.cancelFromThisOpDay && opDay.amount !== originalAmount && opDay.amount < adjustmentLimit && opDay.amount !== 0 );
                    if ( isAdjustableOpDay ) {
                        state.operatorsToRemove.opDaysToAdjust.push( {
                            id: opDay.id
                            , pieces: opDay.pieces
                            , amount: null
                        } );
                    } else if ( opDayExists ) {
                        // If an opDay is already in the opDaysToAdjust array, update it.
                        state.operatorsToRemove.opDaysToAdjust.map( opDayToAdjust => {
                            if ( opDayToAdjust.id === opDay.id ) {
                                if ( opDayToAdjust.amount ) {
                                    opDayToAdjust.amount = Number( ( opDay.amount ).toFixed( 2 ) );
                                } else {
                                    opDayToAdjust.pieces = opDay.pieces;
                                }
                            }
                        } );
                    }
                    if ( opDay.pieces === null ) {
                        opDay.cancelFromThisOpDay = true;
                        state.operatorsToRemove.opDaysToAdjust = state.operatorsToRemove.opDaysToAdjust.filter( existing => existing.id !== opDay.id );
                    } else {
                        opDay.cancelFromThisOpDay = false;
                    }
                    if (
                        ( opDay.amount === originalAmount && !opDay.isAdjusted )
                        || ( opDay.amount === opDay.adjustedAmount && opDay.isAdjusted )
                        || opDay.amount === 0
                        || opDay.amount > adjustmentLimit
                    ) {
                        opDay.currentlyAdjusting = false;
                    } else {
                        opDay.currentlyAdjusting = true;
                    }
                    break;
                }
            }

            for ( const opDay of state.adjustableOpDays.hourlyOps ) {
                if ( opDay.id === action.payload.id ) {
                    const originalAmount = opDay.assignment.bid.amount;
                    const adjustmentLimit = originalAmount * 2;
                    opDay.amount = action.payload.adjustedOpDayAmount;
                    opDay.pieces = null;
                    opDay.currentlyAdjusting = true;
                    const isAdjustableOpDay = ( !opDayExists && !opDay.cancelFromThisOpDay && opDay.amount !== originalAmount && opDay.amount < adjustmentLimit && opDay.amount !== 0 );
                    if ( isAdjustableOpDay ) {
                        state.operatorsToRemove.opDaysToAdjust.push( {
                            id: opDay.id
                            , amount: opDay.amount
                            , pieces: null
                        } );
                    } else if ( opDayExists ) {
                        // If an opDay is already in the opDaysToAdjust array, update it.
                        state.operatorsToRemove.opDaysToAdjust.map( opDayToAdjust => {
                            if ( opDayToAdjust.id === opDay.id ) {
                                opDayToAdjust.amount = opDay.amount;
                                opDayToAdjust.pieces = null;
                            }
                        } );
                    }
                    if ( opDay.amount === 0 ) {
                        opDay.cancelFromThisOpDay = true;
                        state.operatorsToRemove.opDaysToAdjust = state.operatorsToRemove.opDaysToAdjust.filter( existing => existing.id !== opDay.id );
                    } else {
                        opDay.cancelFromThisOpDay = false;
                    }
                    if (
                        ( opDay.amount === originalAmount && !opDay.isAdjusted )
                        || ( opDay.amount === opDay.adjustedAmount && opDay.isAdjusted )
                        || opDay.amount === 0
                        || opDay.amount > adjustmentLimit
                    ) {
                        opDay.currentlyAdjusting = false;
                    } else {
                        opDay.currentlyAdjusting = true;
                    }
                    break;
                }
            }
        }
        , addBackfillOp ( state, action: PayloadAction<BackFillOp> ) {
            state.backFillOp = action.payload;
        }
        , updateBackfillOp ( state, action: PayloadAction<{operatorsNeeded: number; opTypeID: string; startDate: string; endDate?: string; workWeek?: IWorkWeekDay[]}> ) {
            state.backFillOp.operatorsNeeded = action.payload.operatorsNeeded;
            state.backFillOp.optypeId = action.payload.opTypeID;
            state.backFillOp.earliestStartTime = action.payload.startDate;
            state.backFillOp.opDate = action.payload.startDate;
            if ( state.backFillOp.arrivalWindow > 0 ) {
                state.backFillOp.latestStartTime = addMinutes( new Date( action.payload.startDate ), state.backFillOp.arrivalWindow ).toISOString();
            }
            if ( action.payload.endDate ) {
                state.backFillOp.multidayEndDate = action.payload.endDate;
            }
            if ( action.payload.workWeek ) {
                state.backFillOp.multidayWorkWeek = action.payload.workWeek.map( day => day.checked ? day.value : undefined ).filter( day => day !== undefined );
                state.backFillOp.multidayEndDate = new Date( action.payload.endDate ?? Date.now() ).toISOString();
            }
        }
        , setPayChecked ( state, action: PayloadAction<boolean> ) {
            state.isPayChecked = action.payload;
        }
    }
    , extraReducers: builder => {
        builder
            // All addCase calls should be added before addMatcher calls
            .addMatcher( adjustmentsApi.endpoints.getActionableOpDays.matchPending, ( state, action ) => {
                state.isLoading = true;
                state.isError = false;
            } )
            .addMatcher( adjustmentsApi.endpoints.getActionableOpDays.matchFulfilled, ( state, action ) => {
                state.isLoading = false;
                state.isError = false;
                const skipSort = action.meta.arg.originalArgs.skipSort;
                const failedAdjustmentIds = action.meta.arg.originalArgs.failedAdjustmentIds;
                if ( skipSort ) {
                    interface ResponseOpDays {
                        [ key: number ]: OpDay;
                    }
                    const hourlyOps: OpDay[] = [];
                    const responseHourlyOpDays: ResponseOpDays = {};
                    for ( const hourlyOpDay of action.payload.hourlyOps ) {
                        if ( !failedAdjustmentIds?.includes( hourlyOpDay.id ) ) {
                            responseHourlyOpDays[ hourlyOpDay.id ] = hourlyOpDay;
                        }
                    }
                    for ( let opDay of state.adjustableOpDays.hourlyOps ) {
                        if ( responseHourlyOpDays[ opDay.id ] ) {
                            opDay = responseHourlyOpDays[ opDay.id ];
                            if ( opDay.isAdjusted ) {
                                opDay.adjustedAmount = opDay.amount / 100;
                                opDay.isAdjustmentsViewOpen = false;
                            }
                            opDay.amount /= 100;
                            opDay.assignment.bid.amount /= 100;
                        }
                        hourlyOps.push( opDay );
                    }
                    const pieceWorkOps: OpDay[] = [];
                    const responsePieceOpDays: ResponseOpDays = {};
                    for ( const pieceOpDay of action.payload.pieceWorkOps ) {
                        if ( !failedAdjustmentIds?.includes( pieceOpDay.id ) ) {
                            responsePieceOpDays[ pieceOpDay.id ] = pieceOpDay;
                        }
                    }
                    for ( let opDay of state.adjustableOpDays.pieceWorkOps ) {
                        if ( responsePieceOpDays[ opDay.id ] ) {
                            opDay = responsePieceOpDays[ opDay.id ];
                            if ( opDay.isAdjusted ) {
                                // If an opDay has been adjusted, set the adjustedPieces and adjustedAmount to the adjusted values.
                                // These are the values that will be considered as the new original values when the user adjusts the opDay again.
                                opDay.adjustedPieces = opDay.pieces;
                                opDay.adjustedAmount = opDay.amount / 100;
                                opDay.isAdjustmentsViewOpen = false;
                            }
                            opDay.amount /= 100;
                            opDay.assignment.bid.amount /= 100;
                            if ( opDay?.assignment?.bid?.pieceWork?.rate ) {
                                opDay.assignment.bid.pieceWork.rate /= 100;
                            }
                        }
                        pieceWorkOps.push( opDay );
                    }
                    state.adjustableOpDays = {
                        hourlyOps
                        , pieceWorkOps
                    };
                    return;
                }
                const hourlyOps: OpDay[] = [];
                const sortedHourlyOps = _.chain( action.payload.hourlyOps )
                    .orderBy( ( opDay: OpDay ) => opDay.isWithinAdjustmentDeadline, 'desc' )
                    .orderBy( ( opDay: OpDay ) => opDay.isAdjusted, 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.status === 'cancelled', 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.status === 'absent', 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.isDisputed, 'asc' )
                    .orderBy ( ( opDay: OpDay ) => opDay.isCancelled, 'asc' )
                    .value();
                for ( let index = 0; index < sortedHourlyOps.length; index++ ) {
                    const opDay = sortedHourlyOps[ index ];
                    if (
                        ( opDay.status === 'cancelled' && opDay.isWithinAdjustmentDeadline )
                        || opDay.status === 'absent'
                        || opDay.status === 'disputed'
                        || opDay.isWithinAdjustmentDeadline
                    ) {
                        if ( opDay.isAdjusted ) {
                            opDay.adjustedAmount = opDay.amount / 100;
                            opDay.isAdjustmentsViewOpen = false;
                        } else if ( opDay.status === 'created' && !opDay.isCancelled && !opDay.isDisputed ) {
                            opDay.isAdjustmentsViewOpen = true;
                        }
                        opDay.amount /= 100;
                        opDay.assignment.bid.amount /= 100;
                        hourlyOps.push( opDay );
                    }
                }
                const pieceWorkOps: OpDay[] = [];
                const sortedPieceworkOps = _.chain( action.payload.pieceWorkOps )
                    .orderBy( ( opDay: OpDay ) => opDay.isWithinAdjustmentDeadline, 'desc' )
                    .orderBy( ( opDay: OpDay ) => opDay.isAdjusted, 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.status === 'cancelled', 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.status === 'absent', 'asc' )
                    .orderBy( ( opDay: OpDay ) => opDay.isDisputed, 'asc' )
                    .orderBy ( ( opDay: OpDay ) => opDay.isCancelled, 'asc' )
                    .value();
                for ( let index = 0; index < sortedPieceworkOps.length; index++ ) {
                    const opDay = sortedPieceworkOps[ index ];
                    if (
                        ( opDay.status === 'cancelled' && opDay.isWithinAdjustmentDeadline )
                        || opDay.status === 'absent'
                        || opDay.status === 'disputed'
                        || opDay.isWithinAdjustmentDeadline
                    ) {
                        if ( opDay.isAdjusted ) {
                            // If an opDay has been adjusted, set the adjustedPieces and adjustedAmount to the adjusted values.
                            // These are the values that will be considered as the new original values when the user adjusts the opDay again.
                            opDay.adjustedPieces = opDay.pieces;
                            opDay.adjustedAmount = opDay.amount / 100;
                            opDay.isAdjustmentsViewOpen = false;
                        } else if ( opDay.status === 'created' && !opDay.isCancelled && !opDay.isDisputed ) {
                            opDay.isAdjustmentsViewOpen = true;
                        }
                        opDay.amount /= 100;
                        opDay.assignment.bid.amount /= 100;
                        if ( opDay?.assignment?.bid?.pieceWork?.rate ) {
                            opDay.assignment.bid.pieceWork.rate /= 100;
                        }
                        pieceWorkOps.push( opDay );
                    }
                }
                state.actionableAdjustmentsResponse = {
                    hourlyOps: sortedHourlyOps
                    , pieceWorkOps: sortedPieceworkOps
                };
                state.adjustableOpDays = {
                    hourlyOps
                    , pieceWorkOps
                };
                state.opDaysToAdjustOrDispute.opDaysToAdjust = [];
                state.opDaysToAdjustOrDispute.opDaysToDispute = [];
                state.totalAdjustedOpDays = 0;
                state.refetchOpDays = false;
            } )
            .addMatcher( adjustmentsApi.endpoints.getActionableOpDays.matchRejected, ( state, action ) => {
                state.isLoading = false;
                state.isError = true;
                state.adjustableOpDays = {
                    hourlyOps: []
                    , pieceWorkOps: []
                };
                state.refetchOpDays = false;
            } )
            .addMatcher( adjustmentsApi.endpoints.adjustOpDays.matchFulfilled, ( state, action ) => {
                action.payload.opDaysAdjusted.forEach( opDay => {
                    state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                        if ( hourlyOpDay.id === opDay.id ) {
                            hourlyOpDay.amount = opDay.amount / 100;
                            hourlyOpDay.isAdjusted = ( opDay.amount / 100 !== hourlyOpDay.assignment.bid.amount );
                            hourlyOpDay.adjustedAmount = opDay.amount / 100;
                            hourlyOpDay.currentlyAdjusting = false;
                            hourlyOpDay.isAdjustmentsViewOpen = false;
                        }
                    } );
                    state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                        if ( pieceWorkOpDay.id === opDay.id ) {
                            pieceWorkOpDay.isAdjusted = ( opDay.pieces !== pieceWorkOpDay.assignment.bid?.pieceWork?.pieces );
                            pieceWorkOpDay.amount = opDay.amount / 100;
                            pieceWorkOpDay.pieces = opDay.pieces;
                            pieceWorkOpDay.adjustedAmount = opDay.amount / 100;
                            pieceWorkOpDay.adjustedPieces = opDay.pieces;
                            pieceWorkOpDay.currentlyAdjusting = false;
                            pieceWorkOpDay.isAdjustmentsViewOpen = false;
                        }
                    } );
                } );
                state.opDaysToAdjustOrDispute.opDaysToAdjust = [];
                state.totalAdjustedOpDays = 0;
                // If an adjustment fails, add it back to the opDaysToAdjust array
                if ( action.payload.opDaysNotAdjusted ) {
                    action.payload.opDaysNotAdjusted.forEach( opDay => {
                        if ( opDay.adjustErrorMessage !== 'Time past adjustment deadline.' ) {
                            state.opDaysToAdjustOrDispute.opDaysToAdjust.push( {
                                id: opDay.id
                                , pieces: opDay.pieces
                                , amount: opDay.pieces ? null : ( opDay.failedAdjustmentAmount ?? 0 ) / 100
                            } );
                            state.totalAdjustedOpDays += 1;
                        }
                    } );
                }
            } )
            .addMatcher( adjustmentsApi.endpoints.disputeOpDays.matchFulfilled, ( state, action ) => {
                action.payload.opDaysDisputed.forEach( opDay => {
                    state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                        if ( hourlyOpDay.id === opDay.id ) {
                            hourlyOpDay.isDisputed = true;
                            hourlyOpDay.status = 'disputed';
                            hourlyOpDay.isAdjustmentsViewOpen = false;
                            if ( opDay.isBlacklisted ) {
                                hourlyOpDay.operator.isBlacklisted = true;
                            }
                        }
                    } );
                    state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                        if ( pieceWorkOpDay.id === opDay.id ) {
                            pieceWorkOpDay.isDisputed = true;
                            pieceWorkOpDay.status = 'disputed';
                            pieceWorkOpDay.isAdjustmentsViewOpen = false;
                            if ( opDay.isBlacklisted ) {
                                pieceWorkOpDay.operator.isBlacklisted = true;
                            }
                        }
                    } );
                } );
            } )
            .addMatcher( adjustmentsApi.endpoints.reverseDisputeOpDays.matchFulfilled, ( state, action ) => {
                //TODO: this endpoint does not return the status of the reversed opday dispute. we are hardcoding it to 'created' for now
                state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                    if ( hourlyOpDay.assignment.id === action.payload.assignmentId ) {
                        hourlyOpDay.isDisputed = false;
                        hourlyOpDay.status = 'created';
                        hourlyOpDay.isCancelled = false;
                        hourlyOpDay.isWithinAdjustmentDeadline = true;
                        hourlyOpDay.isAdjustmentsViewOpen = true;
                    }
                } );
                state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                    if ( pieceWorkOpDay.assignment.id === action.payload.assignmentId ) {
                        pieceWorkOpDay.isDisputed = false;
                        pieceWorkOpDay.status = 'created';
                        pieceWorkOpDay.isCancelled = false;
                        pieceWorkOpDay.isWithinAdjustmentDeadline = true;
                        pieceWorkOpDay.isAdjustmentsViewOpen = true;
                    }
                } );
            } )
            .addMatcher( adjustmentsApi.endpoints.endOp.matchFulfilled, ( state, action ) => {
                const { meta } = action;
                const firstOpDayid = meta.arg.originalArgs.opDaysToCancel[ 0 ].id;
                if ( action.payload.opDaysCancelled.length > 0 ) {
                    action.payload.opDaysCancelled.forEach( opDay => {
                        state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                            if ( hourlyOpDay.id === opDay.id || firstOpDayid === hourlyOpDay.id ) {
                                hourlyOpDay.status = 'cancelled';
                                hourlyOpDay.isCancelled = true;
                                hourlyOpDay.amount = 0;
                                hourlyOpDay.adjustedAmount = 0;
                                hourlyOpDay.currentlyAdjusting = false;
                                hourlyOpDay.isAdjustmentsViewOpen = false;
                                if ( state.isPayChecked && hourlyOpDay.id === firstOpDayid ) {
                                    hourlyOpDay.status = 'created';
                                    hourlyOpDay.isCancelled = true;
                                    hourlyOpDay.amount = opDay.amount / 100;
                                    hourlyOpDay.adjustedAmount = opDay.amount / 100;
                                }
                            }
                        } );
                        state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                            if ( pieceWorkOpDay.id === opDay.id || firstOpDayid === pieceWorkOpDay.id ) {
                                pieceWorkOpDay.status = 'cancelled';
                                pieceWorkOpDay.isCancelled = true;
                                pieceWorkOpDay.pieces = 0;
                                pieceWorkOpDay.adjustedPieces = 0;
                                pieceWorkOpDay.amount = 0;
                                pieceWorkOpDay.adjustedAmount = 0;
                                pieceWorkOpDay.currentlyAdjusting = false;
                                pieceWorkOpDay.isAdjustmentsViewOpen = false;
                                if ( state.isPayChecked && pieceWorkOpDay.id === firstOpDayid ) {
                                    pieceWorkOpDay.status = 'created';
                                    pieceWorkOpDay.isCancelled = true;
                                    pieceWorkOpDay.pieces = opDay.pieces;
                                    pieceWorkOpDay.adjustedPieces = opDay.pieces;
                                    pieceWorkOpDay.amount = opDay.amount / 100;
                                    pieceWorkOpDay.adjustedAmount = opDay.amount / 100;
                                }
                            }
                        } );
                    } );
                    state.operatorsToRemove.opDaysToCancel = [];
                    state.isPayChecked = false;
                }
                if ( action.payload.opDaysAdjusted.length > 0 ) {
                    action.payload.opDaysAdjusted.forEach( opDay => {
                        state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                            if ( hourlyOpDay.id === opDay.id ) {
                                hourlyOpDay.status = 'created';
                                hourlyOpDay.amount = opDay.amount / 100;
                                hourlyOpDay.isAdjusted = !!opDay.isAdjusted;
                                hourlyOpDay.isCancelled = true;
                                hourlyOpDay.adjustedAmount = opDay.amount / 100;
                            }
                        } );
                        state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                            if ( pieceWorkOpDay.id === opDay.id ) {
                                pieceWorkOpDay.status = 'created';
                                pieceWorkOpDay.isAdjusted = !!opDay.isAdjusted;
                                pieceWorkOpDay.isCancelled = true;
                                pieceWorkOpDay.amount = opDay.amount / 100;
                                pieceWorkOpDay.pieces = opDay.pieces;
                                pieceWorkOpDay.adjustedAmount = opDay.amount / 100;
                                pieceWorkOpDay.adjustedPieces = opDay.pieces;
                            }
                        } );
                    } );
                    state.opDaysToAdjustOrDispute.opDaysToAdjust = [];
                    state.operatorsToRemove.opDaysToAdjust = [];
                }
                // If an adjustment fails, add it back to the opDaysToAdjust array
                if ( action.payload.opDaysNotAdjusted.length > 0 ) {
                    state.opDaysToAdjustOrDispute.opDaysToAdjust = [];
                    state.operatorsToRemove.opDaysToAdjust = [];
                    action.payload.opDaysNotAdjusted.forEach( opDay => {
                        if ( opDay.adjustErrorMessage !== 'Time past adjustment deadline.' && opDay.adjustErrorMessage !== 'Op day amount is unchanged.' ) {
                            state.operatorsToRemove.opDaysToAdjust.push( {
                                id: opDay.id
                                , ...( opDay.pieces && { pieces: opDay.pieces } )
                                , amount: opDay.pieces ? null : ( opDay.failedAdjustmentAmount ?? 0 ) / 100
                            } );
                        }
                    } );
                }
                // If cancelling fails, add it back to the opDaysToCancelled array
                if ( action.payload.opDaysNotCancelled.length > 0 ) {
                    state.operatorsToRemove.opDaysToCancel = [];
                    action.payload.opDaysNotCancelled.forEach( opDay => {
                        state.operatorsToRemove.opDaysToCancel.push( {
                            id: opDay.id
                            , startTime: opDay.startTime ?? ''
                            , comment: opDay.comment ?? ''
                            , cancelFromThisOpDay: false
                        } );
                    } );
                }
            } )
            .addMatcher( adjustmentsApi.endpoints.markAbsentOpDay.matchFulfilled, ( state, action ) => {
                state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                    if ( hourlyOpDay.id === action.payload.opDayId ) {
                        hourlyOpDay.status = 'absent';
                        hourlyOpDay.isAdjustmentsViewOpen = false;
                    }
                } );
                state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                    if ( pieceWorkOpDay.id === action.payload.opDayId ) {
                        pieceWorkOpDay.status = 'absent';
                        pieceWorkOpDay.isAdjustmentsViewOpen = false;
                    }
                } );
            } )
            .addMatcher( adjustmentsApi.endpoints.restoreOpDayPay.matchFulfilled, ( state, action ) => {
                state.adjustableOpDays.hourlyOps.forEach( ( hourlyOpDay: OpDay ) => {
                    if ( hourlyOpDay.id === action.payload.opDayId ) {
                        hourlyOpDay.status = 'created';
                        hourlyOpDay.isWithinAdjustmentDeadline = true;
                        hourlyOpDay.isAdjustmentsViewOpen = true;
                    }
                } );
                state.adjustableOpDays.pieceWorkOps.forEach( ( pieceWorkOpDay: OpDay ) => {
                    if ( pieceWorkOpDay.id === action.payload.opDayId ) {
                        pieceWorkOpDay.status = 'created';
                        pieceWorkOpDay.isWithinAdjustmentDeadline = true;
                        pieceWorkOpDay.isAdjustmentsViewOpen = true;
                    }
                } );
            } );
    }
} );

// Selectors
export const selectActionableAdjustmentsResponse = ( state: StoreState ) => state.actionableOps.actionableAdjustmentsResponse;
export const selectAdjustableOpDays = ( state: StoreState ) => state.actionableOps.adjustableOpDays;
export const selectOpDaysToAdjustOrDispute = ( state: StoreState ) => state.actionableOps.opDaysToAdjustOrDispute;
export const selectTotalAdjustedOpDays = ( state: StoreState ) => state.actionableOps.totalAdjustedOpDays;
export const selectRefetchOpDays = ( state: StoreState ) => state.actionableOps.refetchOpDays;
export const selectIsLoading = ( state: StoreState ) => state.actionableOps.isLoading;
export const selectIsError = ( state: StoreState ) => state.actionableOps.isError;
export const selectBackFillOp = ( state: StoreState ) => state.actionableOps.backFillOp;
export const selectAdjustedPieces = ( state: StoreState, opDayId: number ) => state.actionableOps.adjustableOpDays.pieceWorkOps.find( ( opDay: OpDay ) => opDay.id === opDayId )?.pieces;

export const {
    setOpDayPay
    , addToAdjustedOperatorToRemove
    , addBackfillOp
    , updateBackfillOp
    , cancelAdjustment
    , editAdjustment
    , setPayChecked
    , cancelEditAdjustment
} = actionableOpsSlice.actions;
export default actionableOpsSlice.reducer;
