import {
    PerformanceState
    , PerformanceDataActionType
    , SET_PERFORMANCE_DATA
} from './types';

const initialState: PerformanceState = {
    data: {}
};

export const setPerformanceData = ( performanceData: any ) => {
    return {
        type: SET_PERFORMANCE_DATA
        , data: performanceData
    };
};

export default function reducer (
    state: PerformanceState = initialState
    , action: PerformanceDataActionType
) {
    switch ( action.type ) {
        case SET_PERFORMANCE_DATA:
            return Object.assign(
                {}
                , state
                , {
                    //eslint-disable-next-line
                    data: action.data && action.data.hasOwnProperty( 'opData' )
                        ? action.data
                        : {}
                }
            );
        default:
            return state;
    }
}
