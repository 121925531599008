import { ActionType } from '../types';

export const SET_RATINGS = 'bp/ratings/FETCH_OPS';
export const SET_UNRATED_OPERATORS = 'bp/rating/SET_UNRATED_OPERATORS';
export const RESET = 'bp/ratings/RESET';

export interface RatingsState {
    ratings: { [ key: string ]: any };
    unratedOperators: number | null;
}

interface SetRatingsAction extends ActionType {
    type: typeof SET_RATINGS;
}
interface SetUnratedOPeratorsAction extends ActionType {
    type: typeof SET_UNRATED_OPERATORS;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}

export type RatingsActionType = SetRatingsAction
    | SetUnratedOPeratorsAction
    | ResetAction;
