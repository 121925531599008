import {
    RESET
    , RatingsActionType
    , RatingsState
    , SET_RATINGS
    , SET_UNRATED_OPERATORS
} from './types';

const initialState: RatingsState = {
    ratings: []
    , unratedOperators: null
};

export const setRatings = ( ratings: { [ key: string ]: any } ): RatingsActionType => {
    return {
        type: SET_RATINGS
        , data: ratings
    };
};

export const setUnratedOperators = ( unratedOperators: number ): RatingsActionType => {
    return {
        type: SET_UNRATED_OPERATORS
        , data: unratedOperators
    };
};

export const resetRatings = (): RatingsActionType => {
    return {
        type: RESET
    };
};

export default function reducer (
    state: RatingsState = initialState
    , action: RatingsActionType
) {
    switch ( action.type ) {
        case SET_RATINGS:
            return Object.assign(
                {}
                , state
                , {
                    ratings: action.data && action.data.length
                        ? action.data
                        : []
                }
            );
        case SET_UNRATED_OPERATORS:
            return {
                ...state
                , unratedOperators: action.data
            };
        case RESET:
            return Object.assign( {}, initialState );
        default:
            return state;
    }
}
