import { cloneDeep } from 'lodash';
import {
    FETCH_MESSAGES
    , FETCH_MESSAGES_ERROR
    , FETCH_MESSAGES_SUCCESS
    , FETCH_UNREAD_COUNT
    , FETCH_UNREAD_COUNT_ERROR
    , FETCH_UNREAD_COUNT_SUCCESS
    , MARK_MESSAGE_READ
    , MARK_MESSAGE_READ_ERROR
    , MARK_MESSAGE_READ_SUCCESS
    , MessageActionType
    , MessagesState
    , RESET
    , UPDATE_UNREAD_MESSAGE_COUNT
} from './types';

import { MessageFeedSection } from '@/types/messages';

const initialState: MessagesState = {
    pendingMessageFetch: false
    , pendingMessageCountFetch: false
    , pendingMarkRead: false
    , pendingMarkReadMessageIds: []
    , messageFetchError: false
    , messageCountFetchError: false
    , markReadError: false
    , messageFeedSections: []
    , unreadMessageCount: 0
};

export const resetMessages = (): MessageActionType => {
    return {
        type: RESET
    };
};

export const updateUnreadMessageCount = ( unreadMessageCount: number ): MessageActionType => {
    return {
        type: UPDATE_UNREAD_MESSAGE_COUNT
        , data: unreadMessageCount
    };
};

const updateReadMessages = ( messageIds: number[], messageFeedSections: MessageFeedSection[] ): MessageFeedSection[] => {
    const sections = cloneDeep( messageFeedSections );
    outer:
    for ( const messageId of messageIds ) {
        for ( const section of sections ) {
            for ( const message of section.messages ) {
                if ( messageId === message.id ) {
                    message.isRead = true;
                    continue outer;
                }
            }
        }
    }
    return sections;
};

export default function reducer ( state: MessagesState = initialState, action: MessageActionType ): MessagesState {
    let messageFeedSections;
    switch ( action.type ) {
        case FETCH_MESSAGES:
            return Object.assign( {}, state, {
                pendingMessageFetch: true
                , messageFetchError: false
                , messageFeedSections: []
            } );
        case FETCH_MESSAGES_ERROR:
            return Object.assign( {}, state, {
                pendingMessageFetch: false
                , messageFetchError: true
                , messageFeedSections: []
            } );
        case FETCH_MESSAGES_SUCCESS:
            return Object.assign( {}, state, {
                pendingMessageFetch: false
                , messageFetchError: false
                , messageFeedSections: action && action.data && action.data.sections
                    ? action.data.sections
                    : []
            } );
        case FETCH_UNREAD_COUNT:
            return Object.assign( {}, state, {
                pendingMessageCountFetch: true
                , messageCountFetchError: false
            } );
        case FETCH_UNREAD_COUNT_ERROR:
            return Object.assign( {}, state, {
                pendingMessageCountFetch: false
                , messageCountFetchError: true
            } );
        case FETCH_UNREAD_COUNT_SUCCESS:
            return Object.assign( {}, state, {
                pendingMessageCountFetch: false
                , messageCountFetchError: false
                , unreadMessageCount: action.data && !isNaN( parseInt( action.data ) )
                    ? parseInt( action.data )
                    : 0
            } );
        case MARK_MESSAGE_READ:
            return Object.assign( {}, state, {
                pendingMarkRead: true
                , markReadError: false
                , pendingMarkReadMessageIds: action && action.meta && action.meta.data
                    ? action.meta.data.feedMsgIds
                    : []
            } );
        case MARK_MESSAGE_READ_SUCCESS:
            messageFeedSections = updateReadMessages( state.pendingMarkReadMessageIds, state.messageFeedSections );
            return Object.assign( {}, state, {
                pendingMarkRead: false
                , markReadError: false
                , messageFeedSections
                , pendingMarkReadMessageIds: []
            } );
        case MARK_MESSAGE_READ_ERROR:
            return Object.assign( {}, state, {
                pendingMarkRead: false
                , markReadError: true
                , messageFeedSections: state.messageFeedSections
                , pendingMarkReadMessageIds: []
            } );
        case RESET:
            return Object.assign( {}, initialState );
        case UPDATE_UNREAD_MESSAGE_COUNT:
            return Object.assign( {}, state, { unreadMessageCount: action.data } );
        default:
            return state;
    }
}
