import {
    ActionType
    , AsyncActionType
} from '../types';

export const FETCH_OPS = 'bp/ops/FETCH_OPS';
export const FETCH_OPS_SUCCESS = 'bp/ops/FETCH_OPS_SUCCESS';
export const FETCH_OPS_ERROR = 'bp/ops/FETCH_OPS_ERROR';
export const OPEN_BACKFILL_FORM = 'bp/ops/OPEN_BACKFILL_FORM';
export const CLOSE_BACKFILL_FORM = 'bp/ops/CLOSE_BACKFILL_FORM';
export const RESET = 'bp/ops/RESET';

export interface OpsState {
    ops: [];
    error: boolean;
    pending: boolean;
    isBackfillFormOpen: boolean;
}

interface FetchOpsAction extends AsyncActionType {
    type: typeof FETCH_OPS;
}

interface FetchOpsSuccessAction extends AsyncActionType {
    type: typeof FETCH_OPS_SUCCESS;
}

interface FetchOpsErrorAction extends AsyncActionType {
    type: typeof FETCH_OPS_ERROR;
}

interface OpenBackfillFormAction extends ActionType {
    type: typeof OPEN_BACKFILL_FORM;
}

interface CloseBackfillFormAction extends ActionType {
    type: typeof CLOSE_BACKFILL_FORM;
}

interface ResetAction extends AsyncActionType {
    type: typeof RESET;
}

export type OpsActionType = FetchOpsAction
    | FetchOpsSuccessAction
    | FetchOpsErrorAction
    | ResetAction
    | OpenBackfillFormAction
    | CloseBackfillFormAction;
