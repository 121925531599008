import {
    FETCH_TEMPLATES
    , FETCH_TEMPLATES_ERROR
    , FETCH_TEMPLATES_SUCCESS
    , OpTemplatesActionType
    , OpTemplatesState
} from './types';

const initialState: OpTemplatesState = {
    pending: false
    , error: false
    , templates: []
};

export const fetchTemplates = (): OpTemplatesActionType => {
    return {
        type: FETCH_TEMPLATES
        , pending: true
    };
};

export const setFetchTemplatesError = (): OpTemplatesActionType => {
    return {
        type: FETCH_TEMPLATES_ERROR
        , pending: false
        , error: true
    };
};

export const setFetchTemplatesSuccess = ( templates: any[] ): OpTemplatesActionType => {
    return {
        type: FETCH_TEMPLATES_SUCCESS
        , pending: false
        , templates
    };
};

export default function reducer ( state: OpTemplatesState = initialState, action: OpTemplatesActionType ) {
    switch ( action.type ) {
        case FETCH_TEMPLATES:
            return {
                ...state
                , error: false
                , errorMessage: ''
                , pending: true
                , templates: []
            };
        case FETCH_TEMPLATES_ERROR:
            return {
                ...state
                , pending: false
                , templates: []
                , error: action.error
            };
        case FETCH_TEMPLATES_SUCCESS:
            return {
                ...state
                , pending: false
                , templates: action.templates
            };
        default:
            return state;
    }
}
