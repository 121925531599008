import BusinessLocation from '@/types/businesslocation';
import { OpContact } from '@/types/opcontact';
import OpSkill from '@/types/opskill';
import OpTerm from '@/types/opterm';
import OpType from '@/types/optype';
import { Tag } from '@/types/tag';
import WorkArea from '@/types/workarea';

import { ActionType } from '../types';

// Initial State
export interface OpConfigState {
    opSkills: OpSkill[];
    opTerms: OpTerm[];
    opTypes: OpType[];
    opContacts: OpContact[];
    locations: BusinessLocation[];
    workAreas: WorkArea[];
    tags: Tag[];
    latLng: any;
    marketRates: { [ opskillId: string ]: number };
    findOpSkillName: ( opskillId: number ) => string;
    findOpTermName: ( optermId: number ) => string;
    findOpTypeName: ( optypeId: number ) => string;
    findOpContactName: ( opcontactId: number ) => string;
    findLocationName: ( locationId: number ) => string;
    findWorkAreaName: ( workAreaId: number ) => string;
    findTagName: ( tagId: number ) => string;
    findLocationLatLng: ( locationId: number ) => any;
}

// Actions
export const SET_OPSKILLS = 'bp/opconfig/SET_OPSKILLS';
export const SET_OPTERMS = 'bp/opterms/SET_OPTERMS';
export const SET_OPTYPES = 'bp/opconfig/SET_OPTYPES';
export const SET_OPCONTACTS = 'bp/opconfig/SET_OPCONTACTS';
export const SET_LOCATIONS = 'bp/opconfig/SET_LOCATIONS';
export const SET_WORKAREAS = 'bp/opconfig/SET_WORKAREAS';
export const SET_TAGS = 'bp/opconfig/SET_TAGS';
export const SET_DATA = 'bp/opconfig/SET_DATA';
export const RESET = 'bp/opconfig/RESET';
export const SET_MARKET_RATES = 'bp/opconfig/SET_MARKET_RATES';

// Action Type

interface SetOpSkillsAction extends ActionType {
    type: typeof SET_OPSKILLS;
}

interface SetOpTermsAction extends ActionType {
    type: typeof SET_OPTERMS;
}

interface SetOpTypesAction extends ActionType {
    type: typeof SET_OPTYPES;
}

interface SetOpContactsAction extends ActionType {
    type: typeof SET_OPCONTACTS;
}

interface SetLocationsAction extends ActionType {
    type: typeof SET_LOCATIONS;
}

interface SetWorkAreasAction extends ActionType {
    type: typeof SET_WORKAREAS;
}

interface SetTagsAction extends ActionType {
    type: typeof SET_TAGS;
}

interface SetDataAction extends ActionType {
    type: typeof SET_DATA;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}

interface SetMarketRatesAction extends ActionType {
    type: typeof SET_MARKET_RATES;
}

export type OpConfigActionType = SetOpSkillsAction
    | SetOpTermsAction
    | SetOpTypesAction
    | SetOpContactsAction
    | SetLocationsAction
    | SetWorkAreasAction
    | SetTagsAction
    | SetDataAction
    | ResetAction
    | SetMarketRatesAction;
