import { ActionType } from '../types';

export const SET_NON_PEAK_OVERTIME = 'bp/value_calculator/SET_NON_PEAK_OVERTIME ';
export const SET_PEAK_OVERTIME = 'bp/value_calculator/SET_PEAK_OVERTIME';
export const SET_LEAD_TIME = 'bp/value_calculator/SET_LEAD_TIME';
export const SET_COSTS_PER_UNIT = 'bp/value_calculator/SET_COSTS_PER_UNIT';
export const SET_HIRING_COSTS = 'bp/value_calculator/SET_HIRING_COSTS';
export const SET_OVERHEAD_COSTS = 'bp/value_calculator/SET_OVERHEAD_COSTS';
export const SET_PEAK_SEASON_EFFICIENCY_GAINS = 'bp/value_calculator/SET_PEAK_SEASON_EFFICIENCY_GAINS';
export const SET_TOTAL_VALUE_CREATED = 'bp/value_calculator/SET_TOTAL_VALUE_CREATED';
export const SET_CALCULATIONS_TABLE_DATA = 'bp/value_calculator/SET_CALCULATIONS_TABLE_DATA';

export interface ValueCalculatorState {
    nonPeakOvertime: any;
    leadTime: any;
    costsPerUnit: any;
    hiringCosts: any;
    overheadCosts: any;
    peakOvertime: any;
    peakSeasonEfficiencyGains: any;
    totalValueCreated: any;
    calculationsTableData: any;
}

interface SetNonPeakOvertime extends ActionType {
    type: typeof SET_NON_PEAK_OVERTIME;
}

interface SetPeakOvertime extends ActionType {
    type: typeof SET_PEAK_OVERTIME;
}

interface SetLeadTime extends ActionType {
    type: typeof SET_LEAD_TIME;
}

interface SetCostsPerUnit extends ActionType {
    type: typeof SET_COSTS_PER_UNIT;
}

interface SetHiringCosts extends ActionType {
    type: typeof SET_HIRING_COSTS;
}

interface SetOverheadCosts extends ActionType {
    type: typeof SET_OVERHEAD_COSTS;
}

interface SetPeakSeasonEfficiencyGains extends ActionType {
    type: typeof SET_PEAK_SEASON_EFFICIENCY_GAINS;
}

interface SetTotalValueCreated extends ActionType {
    type: typeof SET_TOTAL_VALUE_CREATED;
}

interface SetCalculationsTableData extends ActionType {
    type: typeof SET_CALCULATIONS_TABLE_DATA;
}

export type ValueCalculatorActionType = SetNonPeakOvertime
    | SetPeakOvertime
    | SetLeadTime
    | SetCostsPerUnit
    | SetHiringCosts
    | SetOverheadCosts
    | SetPeakSeasonEfficiencyGains
    | SetTotalValueCreated
    | SetCalculationsTableData;
