import {
    OpConfigActionType
    , OpConfigState
    , RESET
    , SET_DATA
    , SET_MARKET_RATES
    , SET_OPSKILLS
} from './types';

import OpSkill from '@/types/opskill';

const initialState: OpConfigState = {
    opSkills: []
    , findOpSkillName: () => ''
    , opTerms: []
    , findOpTermName: () => ''
    , opTypes: []
    , findOpTypeName: () => ''
    , opContacts: []
    , findOpContactName: () => ''
    , locations: []
    , findLocationName: () => ''
    , workAreas: []
    , findWorkAreaName: () => ''
    , tags: []
    , findTagName: () => ''
    , latLng: []
    , findLocationLatLng: () => ''
    , marketRates: {
        0: 0
    }
};

export const setOpSkills = ( opSkills: OpSkill[] ): OpConfigActionType => {
    return {
        type: SET_OPSKILLS
        , data: opSkills
    };
};

export const setData = ( data: { [ key: string ]: any[] } ): OpConfigActionType => {
    return {
        type: SET_DATA
        , data
    };
};

export const resetOpConfig = (): OpConfigActionType => {
    return {
        type: RESET
    };
};

export const setMarketRates = ( marketRates: { [ opskillId: string ]: number } ) => {
    return {
        type: SET_MARKET_RATES
        , data: {
            marketRates
        }
    };
};

const createDataMap = ( data: any[], valueProp: string, labelProp: string ) => {
    const map: { [ key: string ]: string } = {};
    for ( const datum of data ) {
        if ( datum[ valueProp ] && datum[ labelProp ] ) {
            map[ datum[ valueProp ] ] = datum[ labelProp ];
        }
    }
    return map;
};

const createFindDisplayName = ( dataMap: { [ key: string ]: string } ) => ( id: number ): string => {
    //eslint-disable-next-line
    if ( dataMap.hasOwnProperty( id ) ) {
        const displayName = dataMap[ id ];
        return displayName || '';
    }
    return '';
};

const createNewState = ( data: { [ key: string ]: any[] } ) => {
    const newState: { [ key: string ]: any } = {};
    const props = [
        {
            propertyName: 'opSkills'
            , findFunctionName: 'findOpSkillName'
            , dataProp: 'skills'
            , valueProp: 'id'
            , labelProp: 'displayName'
        }
        , {
            propertyName: 'locations'
            , findFunctionName: 'findLocationName'
            , dataProp: 'locations'
            , valueProp: 'id'
            , labelProp: 'name'
        }
        , {
            propertyName: 'workAreas'
            , findFunctionName: 'findWorkAreaName'
            , dataProp: 'workAreas'
            , valueProp: 'id'
            , labelProp: 'name'
        }
        , {
            propertyName: 'opContacts'
            , findFunctionName: 'findOpContactName'
            , dataProp: 'opContacts'
            , valueProp: 'id'
            , labelProp: 'firstName'
            // , labelProp: { firstName: 'firstName', lastName: 'lastName' }
        }
        , {
            propertyName: 'opTypes'
            , findFunctionName: 'findOpTypeName'
            , dataProp: 'types'
            , valueProp: 'id'
            , labelProp: 'opTypeName'
        }
        , {
            propertyName: 'locations'
            , findFunctionName: 'findLocationLatLng'
            , dataProp: 'locations'
            , valueProp: 'id'
            , labelProp: 'latlng'
        }

    ];
    for ( const prop of props ) {
        const propData = data[ prop.dataProp ].slice();
        newState[ prop.propertyName ] = propData;
        const dataMap = createDataMap( propData, prop.valueProp, prop.labelProp );
        newState[ prop.findFunctionName ] = createFindDisplayName( dataMap );
    }
    return newState;
};

export default function reducer ( state: OpConfigState = initialState, action: OpConfigActionType ) {
    switch ( action.type ) {
        case SET_DATA:
            const newState = createNewState( action.data );
            return Object.assign( {}, state, newState );
        case SET_OPSKILLS:
            const opSkillsMap = createDataMap( action.data, 'id', 'displayName' );
            return Object.assign( {}, state, {
                opSkills: action.data
                , findOpSkillName: createFindDisplayName( opSkillsMap )
            } );
        case RESET:
            return Object.assign( {}, initialState );
        case SET_MARKET_RATES:
            return Object.assign( {}, state, { marketRates: Object.assign( {}, state.marketRates, action.data.marketRates ) } );
        default:
            return state;
    }
}
