// Libraries
import {
    createSlice
    , PayloadAction
} from '@reduxjs/toolkit';

// Services

// Types
import { OpDay } from '@/types/opDay';

export const initialOpDayState = {
    id: 0
    , startTime: ''
    , endTime: ''
    , amount: 0
    , pieces: null
    , adjustmentDeadline: ''
    , op: {
        id: 0
        , title: ''
        , description: ''
        , locationId: 0
        , workAreaId: 0
        , workAreaName: ''
        , startTime: ''
        , workDaysOffsets: []
        , breakHours: 0
        , arrivalWindow: null
        , numberOfOperators: 0
        , scheduledPostTime: null
        , ylpOnly: false
        , ylpTags: [ {
            id: 0
            , label: ''
            , businessId: 0
            , createdAt: ''
            , updatedAt: ''
            , color: ''
            , isRemoved: false
        } ]
        , autofill: false
        , autoAccept: false
    }
    , isWithinAdjustmentDeadline: true
    , isWithinInvoicingDeadline: true
    , assignment: {
        id: 0
        , endTime: ''
        , isRateEnabled: true
        , isAdjustEnabled: false
        , isNoShowEnabled: false
        , bid: {
            id: 0
            , rate: 0
            , quantity: 0
            , amount: 0
            , pieceWork: null
        }
    }
    , operator: {
        id: 123
        , firstName: ''
        , lastName: ''
        , fullName: ''
    }
    , extendedPay: {
        extendedRate: 0
        , extendedHours: 0
        , weekToDateHours: 0
    }
} as unknown as OpDay;

export const opDaySlice: any = createSlice( {
    name: 'opDay'
    , initialState: {
        opDay: initialOpDayState
        , currentlyAdjusting: false
    }
    , reducers: {
        setOpDay ( state, action: PayloadAction<OpDay> ) {
            state.opDay = action.payload;
        }
    }
    , extraReducers: {
        // [ adjustmentsApi.endpoints.markNoShow ]: ( state ) => {
        // }
    }
} );

export const {
    setOpDay
} = opDaySlice.actions;

export default opDaySlice.reducer;
