import {
    CLOSE_BACKFILL_FORM
    , FETCH_OPS
    , FETCH_OPS_ERROR
    , FETCH_OPS_SUCCESS
    , OPEN_BACKFILL_FORM
    , OpsActionType
    , OpsState
    , RESET
} from './types';

const initialState: OpsState = {
    pending: false
    , error: false
    , ops: []
    , isBackfillFormOpen: false
};

export const fetchOps = ( businessId: number ): OpsActionType => {
    return {
        type: FETCH_OPS
        , meta: {
            async: true
            , method: 'GET'
            , path: `/ops/business/all/${ businessId }?states=completed,open,actionable`
        }
    };
};

export const openBackfillForm = () => {
    return {
        type: OPEN_BACKFILL_FORM
    };
};

export const closeBackfillForm = () => {
    return {
        type: CLOSE_BACKFILL_FORM
    };
};

export const resetOps = (): OpsActionType => {
    return {
        type: RESET
    };
};

export default function reducer ( state: OpsState = initialState, action: OpsActionType ) {

    switch ( action.type ) {
        case FETCH_OPS:
            return Object.assign(
                {}
                , state
                , {
                    pending: true
                    , error: false
                    , ops: []
                }
            );
        case FETCH_OPS_ERROR:
            return Object.assign(
                {}
                , state
                , {
                    pending: false
                    , error: action.error
                }
            );
        case FETCH_OPS_SUCCESS:
            return Object.assign(
                {}
                , state
                , {
                    pending: false
                    , ops: action.data.completed
                }
            );
        case OPEN_BACKFILL_FORM:
            return Object.assign( {}, state, { isBackfillFormOpen: true } );
        case CLOSE_BACKFILL_FORM:
            return Object.assign( {}, state, { isBackfillFormOpen: false } );
        case RESET:
            return Object.assign( {}, initialState );
        default:
            return state;
    }
}
