import {
    ActionType
    , AsyncActionType
} from '../types';

export const SET_ALL_CONFIG = 'bp/config/SET_ALL_CONFIG';
export const SET_CONFIG_VALUE = 'bp/config/SET_CONFIG_VALUE';
export const RESET = 'bp/config/RESET';

export interface ConfigState {
    [ key: string ]: any;
}

interface GetConfigValueAction extends AsyncActionType {
    type: typeof SET_ALL_CONFIG;
}

interface SetConfigValueAction extends AsyncActionType {
    type: typeof SET_CONFIG_VALUE;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}
export type ConfigActionType = GetConfigValueAction | SetConfigValueAction | ResetAction;
